import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IApiKey } from '@shared-global/interfaces/api-keys/interfaces/api-key.interface';
import { IFindApiKeysByCriteriaRequest } from '@shared-global/interfaces/api-keys/interfaces/find-api-keys-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export type IApiKeysByCriteriaRequest = IFindApiKeysByCriteriaRequest & QueryParamsInterface


@Injectable()
export class ApiKeyService extends AbstractCrudService<
  IApiKey,
  IApiKeysByCriteriaRequest
> {
  protected override feature = 'api-keys';
  protected override endPoint = `/api-keys/`;
}
